/* eslint-disable quotes */
import { AgreementDinamicControl, AgreementFormBranch, AgreementFormUser, IAgreementForm } from '../model/agreement.model';
import { Roles } from '../model/role.interface';
import { COMPANY_ROLES } from '../resources/roles';

export class AgreementFormClass {

  customersAllowed?: AgreementFormUser[];
  title: string;
  dinamicForm: AgreementDinamicControl[];
  id?: number;
  creator?: AgreementFormUser | string;
  createdAt?: string;
  terms: string;
  branch: number | AgreementFormBranch;
  status: string;
  customersAndManagersAllowed: (AgreementFormUser | number)[];

  constructor(agreementForm: IAgreementForm) {
    this.title = agreementForm.title;
    this.dinamicForm = agreementForm.agreementFormElements;
    this.id = agreementForm.id;
    this.createdAt = agreementForm.createdAt;
    this.terms = agreementForm.terms;
    this.branch = agreementForm.branch;
    this.status = agreementForm.status;
    this.creator = agreementForm.customerCreator || agreementForm.enterpriseManagerCreator;
    this.customersAndManagersAllowed = [...agreementForm.customersAllowed, ...agreementForm.enterpriseManagersAllowed];
  }

  static prepareForPublish(agreementFormClass: AgreementFormClass, agreementFormGuid?: string): IAgreementForm {
    const customersAllowed = [];
    const enterpriseManagersAllowed = [];

    if (agreementFormClass.customersAndManagersAllowed?.length) {
      agreementFormClass.customersAndManagersAllowed?.forEach((user) => {
        if (COMPANY_ROLES.includes((user as AgreementFormUser).roles[0] as Roles)) {
          customersAllowed.push((user as AgreementFormUser).id);
        } else {
          enterpriseManagersAllowed.push((user as AgreementFormUser).id);
        }
      });
    }

    agreementFormClass.dinamicForm.forEach(item => {
      Object.keys(item).forEach(key => {
        if (((!item[key] || !item[key].length) && key !== 'required')) {
          item[key] = null;
        };
      });
      delete item['selectedValues'];
      delete item['textFieldEditorValue'];
    });

    const agreementForm: IAgreementForm = {
      title: agreementFormClass.title,
      agreementFormElements: agreementFormClass.dinamicForm,
      customersAllowed: customersAllowed,
      enterpriseManagersAllowed: enterpriseManagersAllowed,
      terms: agreementFormClass.terms,
      branch: agreementFormClass.branch,
      ...(agreementFormGuid && { status: agreementFormClass.status })
    };

    return agreementForm;
  }
}
